<template>
  <div
    v-if="fileData"
    class="d-flex card-container"
    @click="previewURL(fileData)"
  >
    <!-- {{ fileData }} -->
    <div class="d-flex align-center">
      <div v-if="thumbnail" style="width: 200px; height:200px; overflow:hidden">
      <img style="width:100%; " :src="fileData.url">
      </div>
      <v-icon v-else color="#CCCCCC"> mdi-file-document </v-icon>
    </div>
    <div class="d-flex flex-column ml-2">
      <span class="txt12-blue">
        {{ fileData.file_name }}
      </span>
      <span class="txt12-gray80">
        {{ fileData.file_type }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileData: {
      type: Object,
      default: null,
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  computed: {},

  watch: {},

  created() {},

  methods: {
    previewURL(p) {
      const url = [];
      url.push(p.url);
      this.$viewerApi({ images: url });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt12-gray80 {
  @extend .p-2;
  color: $gray-80;
}

.txt12-blue {
  @extend .p-2;
  color: $blue-1;
}

.card-container {
  width: 300px;
  cursor: pointer;
  padding: 10px;
  background: #fafafa;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
</style>
