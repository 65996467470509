<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <template v-if="dtDetail !== null">
      <v-card class="pt-8 pb-14 px-8 mx-2" elevation="0">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span class="txt16-gray50">
              Dibuat Oleh
              <span class="txt16-gray50-bold">
                {{ dtDetail.created_by || "-" }}
              </span>
            </span>
            <span class="txt16-gray50">
              {{ dtDetail.date || "-" }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-space-between">
            <span class="txt16-gray50">
              Entitas
              <span class="txt16-gray50-bold">
                {{ dtDetail.entity_main.name || "-" }}
              </span>
            </span>
          </v-col>
        </v-row>

        <!-- <v-row class="">
          <v-col class="d-flex justify-space-between">
            <span class="txt16-gray50">
              Sub Entitas
              <div class="txt16-gray50-bold"> {{ dtDetail.entity_main.name || '-' }} </div>
            </span>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col>
            <hr style="border: 1px solid #e6e6e6" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-center">
            <span class="txt20-black">
              {{ dtDetail.title || "-" }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="txt16-black" v-html="dtDetail.content" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <hr style="border: 1px solid #e6e6e6" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <card-file
              v-for="(v, i) in dtDetail.list_file"
              :key="'file-' + i"
              class="mb-2"
              :file-data="v"
              :thumbnail="true"
            />
          </v-col>
        </v-row>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";
import CardFile from "../komponen/card/CardFile.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    CardFile,
  },

  data: () => ({
    dtDetail: null,
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      const requestBody = {
        article_hash: this.$route.params.id,
      };

      axios
        .post("/v1/admin/article/detail", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.dtDetail = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-black {
  @extend .p-1;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt20-black {
  @extend .h-5;
  font-weight: bold;
}

.symbol-required {
  color: $red-1;
}
</style>
